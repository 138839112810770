import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Features/FTP/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/AlarmPerm';
import FTPTable from 'components/Web_User_Interface/1440p_Series/Features/FTP/ftpTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Features Menu // Alarm FTP Upload",
  "path": "/Web_User_Interface/1440p_Series/Features/FTP/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "To use the video FTP Upload, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard FTP port is 21.",
  "image": "./WebUI_1440p_SearchThumb_Features_FTP.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Features_FTP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Features Menu // Alarm FTP Upload' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='To use the video FTP Upload, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard FTP port is 21.' image='/images/Search/WebUI_1440p_SearchThumb_Features_FTP.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Features_FTP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Features/FTP/' locationFR='/fr/Web_User_Interface/1440p_Series/Features/FTP/' crumbLabel="Alarm FTP Upload" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "features-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#features-menu",
        "aria-label": "features menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Features Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/594893c806512abf5ffcc20fbbcfdba3/2e367/1440p_Settings_Features_FTP.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACY0lEQVQoz2XSS08TYRTGcb6ECUEFFNpIWwgI2DL36UxhYGbazo20DJgIYkANMaHQEohETFRWEhfGsPATCAtXGMPChTs3Gnd+mL8ZGi7GxZOz++U573s6hoaGyOVy5AYHudN/C2Wuhbf3hZmtT1itIyY3j5hstjPVPGayecx064h4/xS51kQR82Rzg3R1ddHb20tHJpMhSTabJdV3G7MSEz97TbT6nHB1l3DlMt7yDtXlbcqLWwQru1jhEmJBJJ8XGRkeY2R4lI4EOk8qlUZTRGpBhapj4bmX8cvTGKqAIowjFcaQhTFmzBKWEVCpzKOrJqKoXjbMZBIwha4XiecXCKMIzw/w/aA9gxDDNBElmcKEgCBLhE7IQ3uR2KljTRaRJYWOgYGBs3YJ2gZ1arUaQRDgeR6+71/MUqmEoijIsoyqqVSdMrv3Q1488fADDUmU2+B5y3MwjmPCMLzAzsFisYgoJm92j4kJAa9q83Z/i1fbdVxLQBKVSzBpmU6n0TSNer1OFEX/ga7rYts2jmNjGCat1gZ/fv7g5GCP6pSCJEptMDmbqw0X5ueYjUL8BLtYOcB2XCzLYsay0FWNna1Nfn075fvhBx771tnb/tMwAVVNpxzGlJwAt+K10SQVB1MVUBWRcU1lsFBgY22N318/c/LxHS8XbQxdufrLGfr6U1imzvtGlcOGy/K8jxdERIFHtNRgdv2AB0sb7LsV3ngRoW0zpSlMCXkKwxly2YErd5isnE4jiwXW4yKNukLF0tGKJkZRxyzXMWpPmXFqPBIlVjSD/N1RrnV2cv1mNze6e+ju6eEvsBSbE9O++ZEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/594893c806512abf5ffcc20fbbcfdba3/e4706/1440p_Settings_Features_FTP.avif 230w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/d1af7/1440p_Settings_Features_FTP.avif 460w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/7f308/1440p_Settings_Features_FTP.avif 920w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/730d0/1440p_Settings_Features_FTP.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/594893c806512abf5ffcc20fbbcfdba3/a0b58/1440p_Settings_Features_FTP.webp 230w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/bc10c/1440p_Settings_Features_FTP.webp 460w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/966d8/1440p_Settings_Features_FTP.webp 920w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/fbb05/1440p_Settings_Features_FTP.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/594893c806512abf5ffcc20fbbcfdba3/81c8e/1440p_Settings_Features_FTP.png 230w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/08a84/1440p_Settings_Features_FTP.png 460w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/c0255/1440p_Settings_Features_FTP.png 920w", "/en/static/594893c806512abf5ffcc20fbbcfdba3/2e367/1440p_Settings_Features_FTP.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/594893c806512abf5ffcc20fbbcfdba3/c0255/1440p_Settings_Features_FTP.png",
              "alt": "Web User Interface - 1440p Series - Features FTP",
              "title": "Web User Interface - 1440p Series - Features FTP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <FTPTable mdxType="FTPTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "ftp-server-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ftp-server-upload",
        "aria-label": "ftp server upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Server Upload`}</h2>
    <p>{`To use the video FTP UPLOAD, you first have to set your ftp server address and login credentials. E.g. you can use your routers FTP service as FTP Server - in this case simply put in your routers IP as the FTP Server address. If you set up a FTP server on a local PC (for example with Filezilla Server), use the PC’s local IP address. Of course you can use webservers URL’s as well. The standard ports are for `}<strong parentName="p">{`FTP 21`}</strong>{` and `}<strong parentName="p">{`sFTP 22`}</strong>{`.`}</p>
    <p>{`You can define an upload directory for your camera under FTP Folder. The root folder of the FTP server can be reached by `}<strong parentName="p">{`./`}</strong>{`. The separator for the folders is the forward slash. A subfolder (e.g. INSTAR) can be reached by `}<strong parentName="p">{`./INSTAR`}</strong>{`. An ending forward slash is not obligatory, but can be used.`}</p>
    <h3 {...{
      "id": "passive-or-port",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#passive-or-port",
        "aria-label": "passive or port permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Passive or Port`}</h3>
    <p>{`For the FTP Mode you can choose between a passive (`}<strong parentName="p">{`PASV`}</strong>{`) and an active mode (`}<strong parentName="p">{`PORT`}</strong>{`). The standard is the active PASV mode. If your server is installed behind a router with an active network address translation (NAT), or if a firewall is blocking the servers network from an external access by your camera, you should choose the PORT mode.`}</p>
    <h3 {...{
      "id": "use-your-router-or-nas-drive-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#use-your-router-or-nas-drive-as-ftp-server",
        "aria-label": "use your router or nas drive as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Use your Router or NAS Drive as FTP Server`}</h3>
    <p>{`Most modern routers now support USB mass storage devices (USB sticks, hard drives,...) to be plugged in - some even come with a large amount of on-board storage for you to use a Network-Attached-Storage (NAS). This storage spaces can be written on by FTP clients like your INSTAR IP camera. All you need to do is to provide the storage and set up a FTP user inside your router and the camera can send snapshots or even whole alarm video to the server. The following video shows the setup process for an AVM Fritzbox - but you can find `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`more examples here`}</a>{`.`}</p>
    <h3 {...{
      "id": "setting-up-a-ftp-server-on-your-windows-computer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setting-up-a-ftp-server-on-your-windows-computer",
        "aria-label": "setting up a ftp server on your windows computer permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up a FTP server on your Windows Computer`}</h3>
    <p>{`Your camera can upload pictures during an alarm event or you can also upload pictures in a defined time period to any kind of FTP server. Besides online offers for FTP servers or Routers which can function as a FTP server you can also set up your local computer or server to function as a FTP server in order to receive pictures from the camera - `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/FTP_Server_Setup/"
      }}>{`read more here`}</a>{`.`}</p>
    <h2 {...{
      "id": "encryption-and-host-verification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#encryption-and-host-verification",
        "aria-label": "encryption and host verification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Encryption and Host Verification`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/"
        }}>{`FAQs :: Using the sFTP Service with your WQHD Camera`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      